import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from '../axiosConfig';
import { isLoggedIn } from '../controllers/utils';

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    privacyPolicyAccepted: false,
    termsAccepted: false
  });

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('/profile');
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/register', formData);
      console.log(response.data);
      const { token } = response.data;
      localStorage.setItem('token', token);
      navigate('/profile');
      window.location.reload();
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      console.error('Registration Error:', error.response?.data?.message || error.message);
    }
  };

  const clearError = () => {
    setError('');
  };

  return (
    <section className="break-all py-16 mt-16">
      <div className="flex flex-col items-center justify-center px-6 container mx-auto">
        <div className="w-full rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 bg-bgcolor3 mx-4 md:mx-0 border border-gray-500 border-opacity-10">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
              Sign Up for Your Account
            </h1>
            {error && ( 
              <div className="mb-4 p-3 bg-red-200 text-red-700 rounded-lg relative">
                <p className="inline-block">{error}</p>
                <button
                  onClick={clearError}
                  className="absolute top-0 right-0 p-2 focus:outline-none"
                >
                  <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-700" />
                </button>
              </div>
            )}
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg text-black"
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  className="w-full p-3 border rounded-lg text-black"
                />
              </div>
              <div className="mb-6">
                <div className="flex items-center">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    className="w-full p-3 border rounded-lg pl-3 pr-10 text-black"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="ml-[-30px] mr-3"
                  >
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      className="text-gray-500 hover:text-gray-700"
                    />
                  </button>
                </div>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="privacyPolicyAccepted"
                  onChange={handleChange}
                  className="mr-2"
                />
                <label htmlFor="privacyPolicyAccepted" className="text-sm text-gray-400">I accept the <a href="/about" target='_blank' rel="noopener noreferrer" className="text-blue-500">Privacy Policy</a></label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="termsAccepted"
                  onChange={handleChange}
                  className="mr-2"
                />
                <label htmlFor="termsAccepted" className="text-sm text-gray-400">I accept the <a href="/about" target='_blank' rel="noopener noreferrer" className="text-blue-500">Terms & Conditions</a></label>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-300"
              >
                Sign Up
              </button>
              <p className="text-sm font-light text-gray-400">
                Already have an account?{' '}
                <Link to="/login" className="font-medium hover:underline text-blue-500">
                  Log in
                </Link>
              </p>
            </form>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
