import React from 'react';
import { FaEnvelope, FaLock, FaInfoCircle, FaRegFileAlt, FaRegPaperPlane } from 'react-icons/fa';

const SectionCard = ({ title, children, icon }) => (
  <div className="bg-bgcolor3 rounded-lg shadow-lg p-6 mb-8 hover:shadow-xl transition-shadow duration-300 mx-4 md:mx-40 border border-gray-500 border-opacity-10">
    <div className="flex items-center mb-4">
      <div className="text-blue-400 text-3xl mr-3">{icon}</div>
      <h2 className="text-3xl font-bold text-white">{title}</h2>
    </div>
    <div className="text-xl text-gray-300">{children}</div>
  </div>
);

const AboutPage = () => {
  return (
    <div className="py-16 mt-16">
      <div className="container mx-auto">
        <SectionCard title="Our Mission" icon={<FaRegPaperPlane />}>
          Welcome to Classify, an innovative solution for file management and organization. Founded in 2022, our mission is to provide an efficient and easy-to-use tool that simplifies document and file management, helping users around the world save time and increase their productivity. We believe that technology should make life easier, not complicate it. We strive to create an app that is intuitive, powerful, and accessible to everyone.
        </SectionCard>

        <SectionCard title="Contact Us" icon={<FaEnvelope />}>
          We are always here to help you. If you have any questions, comments, or suggestions, please do not hesitate to contact us. You can send us an email to <b className='break-all'>support@bushcoding.com</b> or <b className='break-all'>contact@bushcoding.com</b>. You can also reach us through our <b><a rel="noreferrer" target='_blank' href='https://support.bushcoding.com/' className="text-blue-500 underline hover:text-blue-600 transition duration-200">support page</a></b> and follow us on our <b><a rel="noreferrer" target='_blank' href='https://linktr.ee/bushcoding' className="text-blue-500 underline hover:text-blue-600 transition duration-200">social networks</a></b> to stay updated with the latest news and updates.
        </SectionCard>

        <SectionCard title="Privacy Policy" icon={<FaLock />}>
          <strong>1. Information We Collect</strong><br />
          1.1 Personal Information: Name, email, and any other information you provide when registering.<br />
          1.2 Usage Information: Data about how you use the application, including interactions and preferences.<br /><br />

          <strong>2. Use of Information</strong><br />
          2.1 We use the information collected to improve our service and personalize your experience.<br />
          2.2 We do not share your personal information with third parties, except when necessary to provide the service or when required by law.<br /><br />

          <strong>3. Security</strong><br />
          3.1 We implement security measures to protect your personal information against unauthorized access.<br /><br />

          <strong>4. User Rights</strong><br />
          4.1 You have the right to access, rectify, and delete your personal information.<br />
          4.2 You can exercise these rights from the Classify website.<br /><br />

          <strong>5. Changes to this Policy</strong><br />
          5.1 We reserve the right to update this privacy policy at any time.<br />
          5.2 We will notify you of any changes through our application or website.<br /><br />

          <strong>6. Contact</strong><br />
          For any questions about this policy, you can contact us at: <b className='break-all'>contact@bushcoding.com</b>
        </SectionCard>

        <SectionCard title="Terms & Conditions" icon={<FaInfoCircle />}>
          <strong>1. Introduction</strong><br />
          Welcome to Classify. These terms and conditions govern your use of our service and access to our application. By using our application, you accept these terms in full.<br /><br />

          <strong>2. Use of the Service</strong><br />
          2.1 You must not use Classify in any way that is unlawful or prohibited by these terms.<br />
          2.2 Use of the service is at your own risk. Classify is not responsible for any damage that may arise from the use of the application.<br /><br />

          <strong>3. Premium Subscription</strong><br />
          3.1 The premium subscription is purchased through our website.<br />
          3.2 The subscription is valid for the time chosen during the purchase from when it was purchased.<br />
          3.3 After the subscription time has passed, you will revert to the free plan to avoid automatic payments and problems with refunds.<br />
          3.4 No refunds are offered for purchased subscriptions.<br /><br />

          <strong>4. Intellectual Property</strong><br />
          4.1 All rights, title, and interest in and to the application and its content are the property of Bush Coding.<br />
          4.2 You must not copy, modify, or distribute the content without our permission.<br /><br />

          <strong>5. Modifications</strong><br />
          5.1 We reserve the right to modify these terms at any time.<br />
          5.2 Modifications will come into effect once published on our website.<br /><br />

          <strong>6. Contact</strong><br />
          For any questions about these terms, you can contact us at: <b className='break-all'>contact@bushcoding.com</b>
        </SectionCard>
      </div>
    </div>
  );
};

export default AboutPage;
