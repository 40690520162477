import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const isAuthenticated = localStorage.getItem('token') !== null;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.classList.remove('overflow-hidden');
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return (
    <div>
      <nav className={`bg-transparent backdrop-blur-md fixed w-full z-20 top-0 transition-all duration-300 ${isScrolled ? 'py-4 border-b border-gray-500 border-opacity-10 shadow-custom' : 'py-6 border-b border-gray-500 border-opacity-0 shadow-none'}`}>

        <div className="max-w-screen-xl flex items-center justify-between mx-auto px-4">
          <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={process.env.PUBLIC_URL + '/classifyLBSF.png'} className="h-9" alt="classify logo, bush coding software" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">Classify</span>
          </Link>
          <div className="hidden md:flex space-x-4">
            <NavLink to="/" className="rounded-lg hover:bg-white hover:bg-opacity-10 hover:backdrop-blur-md px-4 py-2 text-white font-medium transition duration-300">Home</NavLink>
            <NavLink to="/download" className="rounded-lg hover:bg-white hover:bg-opacity-10 hover:backdrop-blur-md px-4 py-2 text-white font-medium transition duration-300">Download</NavLink>
            <NavLink to="/about" className="rounded-lg hover:bg-white hover:bg-opacity-10 hover:backdrop-blur-md px-4 py-2 text-white font-medium transition duration-300">About</NavLink>
            <NavLink to="/help" className="rounded-lg hover:bg-white hover:bg-opacity-10 hover:backdrop-blur-md px-4 py-2 text-white font-medium transition duration-300">Help</NavLink>
          </div>

          <div className="md:hidden">
            <button onClick={toggleMenu} className="inline-flex items-center p-2 w-10 h-10 justify-center text-white">
              <span className="sr-only">Open main menu</span>
              <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="w-5 h-5" />
            </button>
          </div>
          {isAuthenticated ? (
            <div className="hidden md:flex items-center space-x-4">
              <Link to="/profile" className="bg-blue-500 shadow-inner text-white font-medium px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300">

                Profile
              </Link>
            </div>
          ) : (
            <div className="hidden md:flex items-center space-x-4">
              <Link to="/login" className="rounded-lg hover:bg-white hover:bg-opacity-10 hover:backdrop-blur-md px-4 py-2 text-white font-medium transition duration-300">
                Log In
              </Link>
              <Link to="/register" className="bg-blue-500 shadow-inner text-white font-medium px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300">
                Get started
              </Link>
            </div>
          )}
        </div>
      </nav>
      {isMenuOpen && (
  <div className="md:hidden fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-90 backdrop-blur-lg z-30">
    <div className="flex justify-end p-4">
      <button onClick={closeMenu} className="text-white text-2xl">
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
    <div className="flex flex-col items-center justify-center space-y-4 h-[80vh]">
      <NavLink
        to="/"
        className="text-xl text-white font-medium hover:bg-white hover:bg-opacity-10 hover:backdrop-blur-md px-4 py-2 rounded-lg transition duration-300"
        onClick={closeMenu}
      >
        Home
      </NavLink>
      <NavLink
        to="/download"
        className="text-xl text-white font-medium hover:bg-white hover:bg-opacity-10 hover:backdrop-blur-md px-4 py-2 rounded-lg transition duration-300"
        onClick={closeMenu}
      >
        Download
      </NavLink>
      <NavLink
        to="/about"
        className="text-xl text-white font-medium hover:bg-white hover:bg-opacity-10 hover:backdrop-blur-md px-4 py-2 rounded-lg transition duration-300"
        onClick={closeMenu}
      >
        About
      </NavLink>
      <NavLink
        to="/help"
        className="text-xl text-white font-medium hover:bg-white hover:bg-opacity-10 hover:backdrop-blur-md px-4 py-2 rounded-lg transition duration-300"
        onClick={closeMenu}
      >
        Help
      </NavLink>
      {isAuthenticated ? (
        <Link
          to="/profile"
          className="bg-blue-500 text-white font-medium px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300"
          onClick={closeMenu}
        >
          Profile
        </Link>
      ) : (
        <>
          <Link
            to="/login"
            className="text-xl text-white hover:text-gray-400 font-medium transition duration-300"
            onClick={closeMenu}
          >
            Log in
          </Link>
          <Link
            to="/register"
            className="bg-blue-500 text-white text-xl font-medium px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300"
            onClick={closeMenu}
          >
            Get started
          </Link>
        </>
      )}
    </div>
  </div>
)}

    </div>
  );
};

export default Navbar;
