import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  const isAuthenticated = localStorage.getItem('token') !== null;

  return (
    <div className="flex flex-col">
      <div className="text-white py-16 text-center flex items-center justify-center h-screen px-5">
        <div className="container mx-auto">
          <h1 className="text-5xl md:text-6xl font-extrabold mb-4">Take Control of Your Files</h1>
          <p className="text-xl md:text-2xl mb-8 text-gray-300">Organize smarter, work faster.</p>
          {isAuthenticated ? (
            <Link
              to="/profile"
              className="inline-block bg-blue-500 hover:bg-blue-600 hover:shadow-inner text-white font-bold text-lg md:text-xl py-2 px-4 rounded-lg transition duration-300"
            >
              View Profile
            </Link>
          ) : (
            <Link
              to="/register"
              className="inline-block bg-blue-500 hover:bg-blue-600 hover:shadow-inner text-white font-bold text-lg md:text-xl py-2 px-4 rounded-lg transition duration-300"
            >
              Create an Account
            </Link>
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center py-10 bg-bgcolor3 text-white border border-gray-500 border-opacity-10">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-center">
          <div className="md:w-1/2 m-5">
            <h2 className="text-4xl md:text-5xl font-bold mb-4">Who we are?</h2>
            <p className="text-lg md:text-xl text-gray-300">
              Welcome to Classify by Bush Coding, founded in 2022, our mission is to provide an efficient, user-friendly tool for file management that enhances productivity. We believe technology should simplify life, and we strive to create an intuitive, powerful, and accessible application. We are dedicated to high-quality standards in all aspects, from new features to customer support, ensuring exceptional service.
            </p>
          </div>
          <div className="m-5">
            <img src={process.env.PUBLIC_URL + '/cartons.svg'} alt="Cartoons logo by Classify, software of Bush Coding" className="w-64 md:w-80" />
          </div>
        </div>
      </div>

      <div className="py-20">
        <div className="container mx-auto text-white">
          <h2 className="text-4xl md:text-5xl font-bold mb-4 text-center">Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-5 md:mx-44">
            {['User-Friendly Interface', 'Secure File Organization', 'High-Quality Support'].map((feature, index) => (
              <div key={index} className="bg-bgcolor3 p-4 m-2 rounded-lg shadow-md text-center border border-gray-500 border-opacity-10">
                <h3 className="text-xl md:text-2xl font-bold mb-4">{feature}</h3>
                <p className="text-base md:text-lg text-gray-300 mb-4">
                  {index === 0 && "Classify offers an intuitive and easy to navigate interface, making file management simple and accessible for all users."}
                  {index === 1 && "The application ensures that your files are organized efficiently and securely, keeping your information protected and easily accessible."}
                  {index === 2 && "Classify is committed to providing exceptional customer support and continuous updates, ensuring a reliable and top-notch user experience."}
                </p>
                <div className="flex justify-center m-3">
                  <img src={process.env.PUBLIC_URL + `/${index + 1}.svg`} alt={`Features by Classify, software of Bush Coding`} className="w-32 md:w-40" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="text-white py-20 text-center px-5">
        <div className="container mx-auto">
          <h2 className="text-5xl font-extrabold mb-4">Join us today!</h2>
          <p className="text-xl mb-8">Discover everything we offer and experience a higher level of organization in your files.</p>
          {isAuthenticated ? (
            <Link
              to="/profile"
              className="inline-block bg-blue-500 hover:bg-blue-600 hover:shadow-inner text-white font-bold text-lg md:text-xl py-2 px-4 rounded-lg transition duration-300"
            >
              Go to my profile!
            </Link>
          ) : (
            <Link
              to="/register"
              className="inline-block bg-blue-500 hover:bg-blue-600 hover:shadow-inner text-white font-bold text-lg md:text-xl py-2 px-4 rounded-lg transition duration-300"
            >
              Create your account now!
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
