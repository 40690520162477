import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from '../axiosConfig';
import { isNotLoggedIn } from '../controllers/utils';

const ProfilePage = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const [decodedToken, setDecodedToken] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [isDeletingAccount, setIsDeletingAccount] = useState(false);
    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        daPassword: '',
    });
    const [formData, setFormData] = useState({
        uid: '',
        name: '',
        email: ''
    });
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showDaPassword, setShowDaPassword] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (isNotLoggedIn()) {
            navigate('/login');
        }
    }, [navigate]);

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        navigate('/login');
        window.location.reload();
    }, [navigate]);

    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            setDecodedToken(decoded);
            setFormData({ uid: decoded.uid, name: decoded.name, email: decoded.email });
            const currentTime = Math.floor(Date.now() / 1000);
            if (decoded.exp < currentTime) {
                handleLogout();
            }
        }
    }, [token, handleLogout]);

    const handleUpgradePlan = () => {
        navigate('/upgrade');
    };

    const formatPremiumExpiry = (expiry) => {
        const expiryDate = new Date(expiry);
        const day = expiryDate.getDate();
        const month = expiryDate.getMonth() + 1;
        const year = expiryDate.getFullYear();
        const hours = expiryDate.getHours();
        const minutes = expiryDate.getMinutes();
        const seconds = expiryDate.getSeconds();
        return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setFormData({ uid: decodedToken.uid, name: decodedToken.name, email: decodedToken.email });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const togglePasswordVisibility = (field) => {
        switch (field) {
            case 'currentPassword':
                setShowCurrentPassword(!showCurrentPassword);
                break;
            case 'newPassword':
                setShowNewPassword(!showNewPassword);
                break;
            case 'confirmPassword':
                setShowConfirmPassword(!showConfirmPassword);
                break;
            case 'daPassword':
                setShowDaPassword(!showDaPassword);
                break;
            default:
                console.error(`Unknown field: ${field}`);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/update-profile', formData);
            const { token } = response.data;
            if (token) {
                localStorage.setItem('token', token);
                const decoded = jwtDecode(token);
                setDecodedToken(decoded);
                setIsEditing(false);
            } else {
                console.error('Failed to update profile:', response.data);
            }
        } catch (error) {
            setError(error.response?.data?.message || error.message);
            console.error('Reset Password Error:', error.response?.data?.message || error.message);
        }
    };

    const handleChangePassword = (e) => {
        const { name, value } = e.target;
        setPasswordData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleChangePasswordSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/change-password', {
                uid: formData.uid,
                currentPassword: passwordData.currentPassword,
                newPassword: passwordData.newPassword,
                confirmPassword: passwordData.confirmPassword,
                daPassword: passwordData.daPassword
            });
            if (response.data.status === true) {
                setIsChangingPassword(false);
                setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '', daPassword: '' });
            } else {
                console.error('Failed to change password:', response.data);
            }
        } catch (error) {
            setError(error.response?.data?.message || error.message);
            console.error('Reset Password Error:', error.response?.data?.message || error.message);
        }
    };

    const handleDeleteAccount = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/delete-account', {
                uid: formData.uid,
                daPassword: passwordData.daPassword,
            });
            if (response.data.status === true) {
                setIsDeletingAccount(false);
                handleLogout();
            } else {
                console.error('Failed to delete account:', response.data);
            }
        } catch (error) {
            setError(error.response?.data?.message || error.message);
            console.error('Reset Password Error:', error.response?.data?.message || error.message);
        }
    };


    const clearError = () => {
        setError('');
    };

    useEffect(() => {
        return () => {
            setError('');
        };
    }, [isEditing, isChangingPassword, isDeletingAccount]);

    return (
        <div className="break-all text-white text-center flex items-center justify-center py-16 mt-16">
            <div className="container mx-auto ">
                {decodedToken ? (
                    isEditing ? (
                        <form onSubmit={handleSubmit}>
                            <div className="bg-bgcolor3 p-6 rounded-lg shadow-md border border-gray-500 border-opacity-10 max-w-[600px] mx-auto">
                                {error && (
                                    <div className="mb-4 p-3 bg-red-200 text-red-700 rounded-lg relative">
                                        <p className="inline-block">{error}</p>
                                        <button
                                            onClick={clearError}
                                            className="absolute top-0 right-0 p-2 focus:outline-none"
                                        >
                                            <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-700" />
                                        </button>
                                    </div>
                                )}
                                <div className="mb-4">
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full p-3 rounded-lg pr-10 text-black"
                                    />
                                </div>
                                <div className="mb-4">
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full p-3 rounded-lg pr-10 text-black"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <button
                                        type="submit"
                                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700 w-full"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleCancelEdit}
                                        className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 w-full mt-4"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    ) : isChangingPassword ? (

                        <form onSubmit={handleChangePasswordSubmit}>
                            <div className="bg-bgcolor3 p-6 rounded-lg shadow-md border border-gray-500 border-opacity-10 max-w-[600px] mx-auto">
                                {error && (
                                    <div className="mb-4 p-3 bg-red-200 text-red-700 rounded-lg relative">
                                        <p className="inline-block">{error}</p>
                                        <button
                                            onClick={clearError}
                                            className="absolute top-0 right-0 p-2 focus:outline-none"
                                        >
                                            <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-700" />
                                        </button>
                                    </div>
                                )}
                                <div className="mb-4">
                                    <div className="flex items-center">
                                        <input
                                            type={showCurrentPassword ? 'text' : 'password'}
                                            name="currentPassword"
                                            placeholder="Current Password"
                                            value={passwordData.currentPassword}
                                            onChange={handleChangePassword}
                                            className="w-full p-3 rounded-lg pr-10 text-black"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility('currentPassword')}
                                            className="ml-[-30px] mr-3"
                                        >
                                            <FontAwesomeIcon
                                                icon={showCurrentPassword ? faEyeSlash : faEye}
                                                className="text-gray-500 hover:text-gray-700"
                                            />
                                        </button>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <div className="flex items-center">
                                        <input
                                            type={showNewPassword ? 'text' : 'password'}
                                            name="newPassword"
                                            placeholder="New Password"
                                            value={passwordData.newPassword}
                                            onChange={handleChangePassword}
                                            className="w-full p-3 rounded-lg pr-10 text-black"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility('newPassword')}
                                            className="ml-[-30px] mr-3"
                                        >
                                            <FontAwesomeIcon
                                                icon={showNewPassword ? faEyeSlash : faEye}
                                                className="text-gray-500 hover:text-gray-700"
                                            />
                                        </button>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <div className="flex items-center">
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            value={passwordData.confirmPassword}
                                            onChange={handleChangePassword}
                                            className="w-full p-3 rounded-lg pr-10 text-black"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility('confirmPassword')}
                                            className="ml-[-30px] mr-3"
                                        >
                                            <FontAwesomeIcon
                                                icon={showConfirmPassword ? faEyeSlash : faEye}
                                                className="text-gray-500 hover:text-gray-700"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <button
                                        type="submit"
                                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700 w-full"
                                    >
                                        Change Password
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setIsChangingPassword(false)}
                                        className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 w-full mt-4"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    ) : isDeletingAccount ? (

                        <form onSubmit={handleDeleteAccount}>
                            <div className="bg-bgcolor3 p-6 rounded-lg shadow-md border border-gray-500 border-opacity-10 max-w-[600px] mx-auto">
                                {error && (
                                    <div className="mb-4 p-3 bg-red-200 text-red-700 rounded-lg relative">
                                        <p className="inline-block">{error}</p>
                                        <button
                                            onClick={clearError}
                                            className="absolute top-0 right-0 p-2 focus:outline-none"
                                        >
                                            <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-700" />
                                        </button>
                                    </div>
                                )}
                                <div className="mb-4">
                                    <div className="flex items-center">
                                        <input
                                            type={showDaPassword ? 'text' : 'password'}
                                            name="daPassword"
                                            placeholder="Current Password"
                                            value={passwordData.daPassword}
                                            onChange={handleChangePassword}
                                            className="w-full p-3 rounded-lg pr-10 text-black"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => togglePasswordVisibility('daPassword')}
                                            className="ml-[-30px] mr-3"
                                        >
                                            <FontAwesomeIcon
                                                icon={showDaPassword ? faEyeSlash : faEye}
                                                className="text-gray-500 hover:text-gray-700"
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <button
                                        type="submit"
                                        className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700 w-full"
                                    >
                                        Delete Account
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setIsDeletingAccount(false)}
                                        className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-700 w-full mt-4"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    ) : (
                        <div className="grid grid-cols-1 mx-4 md:mx-40 md:grid-cols-2 xl:grid-cols-3 gap-6">
                            <div className="bg-bgcolor3 p-6 rounded-lg shadow-md border border-gray-500 border-opacity-10">
                                <h3 className="text-2xl font-bold text-blue-400 mb-4 text-left">Identity</h3>
                                <div className="mb-4">
                                    <p className="text-gray-400 text-left">
                                        User ID: <span className="text-white">{decodedToken.uid}</span>
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <p className="text-gray-400 text-left">
                                        Name: <span className="text-white">{decodedToken.name}</span>
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <p className="text-gray-400 text-left">
                                        Email: <span className="text-white">{decodedToken.email}</span>
                                    </p>
                                </div>
                                <button
                                    onClick={handleEdit}
                                    className="w-full bg-yellow-500 hover:bg-yellow-600 text-white py-3 px-4 rounded-lg transition-colors"
                                >
                                    Edit Profile
                                </button>
                            </div>
                            <div className="bg-bgcolor3 p-6 rounded-lg shadow-md border border-gray-500 border-opacity-10">
                                <h3 className="text-2xl font-bold text-green-400 mb-4 text-left">Plan Information</h3>
                                <div className="mb-4">
                                    <p className="text-gray-400 text-left">
                                        Plan: <span className="text-white">{decodedToken.plan}</span>
                                    </p>
                                </div>
                                {decodedToken.premiumExpiration !== null && (
                                    <div className="mb-4">
                                        <p className="text-gray-400 text-left">
                                            Premium Expiry Date: <span className="text-white">{formatPremiumExpiry(decodedToken.premiumExpiration)}</span>
                                        </p>
                                    </div>
                                )}
                                {decodedToken.plan === 'free' && (
                                    <button
                                        onClick={handleUpgradePlan}
                                        className="w-full bg-blue-500 hover:bg-blue-600 text-white py-3 px-4 rounded-lg transition-colors"
                                    >
                                        Upgrade Plan
                                    </button>
                                )}
                            </div>
                            <div className="bg-bgcolor3 p-6 rounded-lg shadow-md border border-gray-500 border-opacity-10">
                                <h3 className="text-2xl font-bold text-yellow-400 mb-4 text-left">Security</h3>
                                <div className="mb-4">
                                    <p className="text-gray-400 text-left">
                                        Session Created: <span className="text-white">{new Date(decodedToken.iat * 1000).toLocaleString()}</span>
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <p className="text-gray-400 text-left">
                                        Session Expires: <span className="text-white">{new Date(decodedToken.exp * 1000).toLocaleString()}</span>
                                    </p>
                                </div>
                                <button
                                    onClick={() => setIsChangingPassword(true)}
                                    className="w-full bg-green-500 hover:bg-green-600 text-white py-3 px-4 rounded-lg transition-colors"
                                >
                                    Change Password
                                </button>
                            </div>
                            <div className="bg-bgcolor3 p-6 rounded-lg shadow-md border border-gray-500 border-opacity-10">
                                <h3 className="text-2xl font-bold text-red-400 mb-4 text-left">Options</h3>
                                <button
                                    onClick={handleLogout}
                                    className="w-full bg-red-500 hover:bg-red-600 text-white py-3 px-4 rounded-lg transition-colors"
                                >
                                    Logout
                                </button>
                                <button
                                    onClick={() => setIsDeletingAccount(true)}
                                    className="w-full bg-red-600 hover:bg-red-700 text-white py-3 px-4 rounded-lg transition-colors mt-4"
                                >
                                    Delete Account
                                </button>
                            </div>
                        </div>
                    )
                ) : (
                    <p>Loading profile data...</p>
                )}
            </div>
        </div>
    );
};

export default ProfilePage;
