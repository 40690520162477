import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-white py-16 px-5">
      <div className="container mx-auto text-center">
        <h1 className="text-5xl md:text-6xl font-extrabold mb-4">404 - Page Not Found</h1>
        <p className="text-xl md:text-2xl mb-8 text-gray-300">
          It seems that the page you are looking for does not exist.
        </p>
        <Link
          to="/"
          className="inline-block bg-blue-500 hover:bg-blue-600 hover:shadow-inner text-white font-bold text-lg md:text-xl py-2 px-4 rounded-lg transition duration-300"
        >
          Return to the Home Page
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
